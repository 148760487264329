import { requestSingleton, printHelper } from 'egenie-common';

const MAC_ADDRESS_KEY = '__egenieMacAddress__';

export function macAddressHelper() {
  const checkUrls: string[] = [
    '/api/cloud/wms/rest/goods/arrival/save',
    '/api/cloud/wms/rest/sort/sorting/bind',
    '/api/cloud/wms/rest/order/check/by/express/no',
    '/api/cloud/wms/rest/order/check/by/pass',
    '/api/cloud/wms/rest/quality/cloud/queryUnique',
    '/api/cloud/wms/rest/quality/cloud/check',
    '/api/cloud/wms/rest/added/value_added/process',
    '/api/cloud/wms/rest/packAndDispatch/scan',
    '/api/cloud/wms/rest/packAndDispatch/printPackAndExpress',
    '/api/cloud/wms/rest/packAndDispatch/print/parcel',
    '/api/cloud/wms/rest/after/sale/get/order/by/unique/code/or/courier',
    '/api/cloud/wms/rest/after/sale/create/empty/order',
    '/api/cloud/wms/rest/after/sale/create/afterSale/order',
  ];

  requestSingleton.getInstance()
    .interceptors
    .request
    .use(async(config) => {
      let macAddress = window.localStorage.getItem(MAC_ADDRESS_KEY);

      // 只添加部分接口(如果全部添加且用户没安装插件,每一个页面请求都会先加载插件,影响太大)
      if (typeof config.url === 'string' && checkUrls.some((item) => item.includes(config.url))) {
        if (!macAddress) {
          try {
            macAddress = await printHelper.lodopPrintPlugin.getMacAddress();
            window.localStorage.setItem(MAC_ADDRESS_KEY, macAddress);
          } catch (e) {
            // mac地址不影响原有业务
            console.log(e);
          }
        }
      }

      return {
        ...config,
        headers: {
          mac: macAddress || '',
          ...config.headers,
        },
      };
    });
}
