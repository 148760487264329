import type { MenuDataItem } from 'egenie-common';
import React from 'react';

export const routes: MenuDataItem[] = [
  {
    path: '',
    children: [
      {
        path: '/egenie-cloud-wms/stockShiftBin/index',
        title: '移库单',
        exact: true,
        component: React.lazy(() => import('./pages/stockShiftBin/index')),
        permissionId: '1637',
      },
      {
        path: '/egenie-cloud-wms/shipper/index',
        title: '货主管理',
        exact: true,
        component: React.lazy(() => import('./pages/shipper/index')),
        permissionId: '255',
      },
      {
        path: '/egenie-cloud-wms/invoice/index',
        exact: true,
        title: '发货单',
        component: React.lazy(() => import('./pages/invoice/index')),
        permissionId: '1640',
      },
      {
        path: '/egenie-cloud-wms/waveManage/index',
        exact: true,
        title: '波次管理',
        component: React.lazy(() => import('./pages/waveManage/index')),
        permissionId: '2668',
      },
      {
        path: '/egenie-cloud-wms/saleCheck/index',
        exact: true,
        title: '验货',
        component: React.lazy(() => import('./pages/saleCheck/index')),
        permissionId: '2670',
      },
      {
        path: '/egenie-cloud-wms/onlyCode/index',
        exact: true,
        title: '唯一码查询',
        component: React.lazy(() => import('./pages/onlyCode/index')),
        permissionId: '2657',
      },
      {
        path: '/egenie-cloud-wms/productSkuPrint/index',
        exact: true,
        title: '条码打印',
        component: React.lazy(() => import('./pages/productSkuPrint/index')),
        permissionId: '2656',
      },
      {
        path: '/egenie-cloud-wms/reprintExpress/index',
        exact: true,
        title: '补打快递',
        component: React.lazy(() => import('./pages/reprintExpress/index')),
        permissionId: '2694',
      },
      {
        path: '/egenie-cloud-wms/wavePickSorting/index',
        exact: true,
        title: '波次分拣',
        component: React.lazy(() => import('./pages/wavePickSorting/index')),
        permissionId: '1649',
      },
      {
        path: '/egenie-cloud-wms/inventoryChecking/index',
        exact: true,
        title: '盘点单',
        component: React.lazy(() => import('./pages/inventoryChecking/index')),
        permissionId: '1635',
      },
      {
        path: '/egenie-cloud-wms/exceptionHandling/index',
        exact: true,
        title: '异常管理',
        component: React.lazy(() => import('./pages/exceptionHandling/index')),
        permissionId: '4002',
      },
      {
        path: '/egenie-cloud-wms/weigh/index',
        exact: true,
        title: '称重',
        component: React.lazy(() => import('./pages/weigh/index')),
        permissionId: '2671',
      },
      {
        path: '/egenie-cloud-wms/stockInventorySearch/index',
        exact: true,
        title: '库位库存查询',
        component: React.lazy(() => import('./pages/stockInventorySearch/index')),
        permissionId: '1639',
      },
      {
        path: '/egenie-cloud-wms/inventoryFullLink/index',
        exact: true,
        title: '库存全链路',
        component: React.lazy(() => import('./pages/inventoryFullLink/index')),
        permissionId: '60044',
      },
      {
        path: '/egenie-cloud-wms/ownerInventorySearch/index',
        exact: true,
        title: '货主库存查询',
        component: React.lazy(() => import('./pages/ownerInventorySearch/index')),
        permissionId: '2673',
      },
      {
        path: '/egenie-cloud-wms/scanGoods/',
        title: '扫描商品',
        children: [
          {
            path: '/egenie-cloud-wms/scanGoods/index',
            title: '收货',
            exact: true,
            component: React.lazy(() => import('./pages/scanGoods/index/index')),
            permissionId: '2672',
          },
          {
            path: '/egenie-cloud-wms/scanGoods/receivingRecord/index',
            title: '到货明细',
            exact: true,
            component: React.lazy(() => import('./pages/scanGoods/receivingRecord/index')),
          },
        ],
      },
      {
        path: '/egenie-cloud-wms/receipt/',
        title: '收货',
        children: [
          {
            path: '/egenie-cloud-wms/receipt/index',
            title: '收货单',
            exact: true,
            component: React.lazy(() => import('./pages/receipt/index')),
            permissionId: '1408',
          },
          {
            path: '/egenie-cloud-wms/receipt/confirm',
            title: '确认收货',
            exact: true,
            component: React.lazy(() => import('./pages/receipt/confirm')),
          },
          {
            path: '/egenie-cloud-wms/receipt/stockIn',
            title: '生成入库单',
            exact: true,
            component: React.lazy(() => import('./pages/receipt/stockIn')),
          },
        ],
      },
      {
        path: '/egenie-cloud-wms/purchaseOrder',
        title: '采购',
        children: [
          {
            path: '/egenie-cloud-wms/purchaseOrder/index',
            title: '采购单',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/index/index')),
            permissionId: '2666',
          },
          {
            path: '/egenie-cloud-wms/purchaseOrder/returnGoods/index',
            title: '采购退货',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/returnGoods/index')),
            permissionId: '60026',
          },
          {
            path: '/egenie-cloud-wms/purchaseOrder/arrivalRecord/index',
            title: '到货记录',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/arrivalRecord/index')),
            permissionId: '60030',
          },
          {
            path: '/egenie-cloud-wms/purchaseOrder/returnOrder/index',
            title: '退货单',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/returnOrder/index')),
            permissionId: '60103',
          },
          {
            path: '/egenie-cloud-wms/purchaseOrder/purchaseStock/index',
            title: '采购备货',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/purchaseStock/index')),
            permissionId: '60113',
          },
          {
            path: '/egenie-cloud-wms/purchaseOrder/supplierReturnOrder/index',
            title: '供应商退回单',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/supplierReturnOrder/index')),
            permissionId: '60140',
          },
          {
            path: '/egenie-cloud-wms/purchaseOrder/returnGoodsReceipt/index',
            title: '退货收货',
            exact: true,
            component: React.lazy(() => import('./pages/purchaseOrder/returnGoodsReceipt/index')),
            permissionId: '60141',
          },
        ],
      },
      {
        path: '/egenie-cloud-wms/afterSales/',
        title: '售后',
        children: [
          {
            path: '/egenie-cloud-wms/afterSales/index',
            title: '售后管理',
            exact: true,
            component: React.lazy(() => import('./pages/afterSales/index')),
            permissionId: '2690',
          },
          {
            path: '/egenie-cloud-wms/afterSales/returnGoods/index',
            exact: true,
            title: '确认退货',
            component: React.lazy(() => import('./pages/afterSales/returnGoods/index')),
            permissionId: '2691',
          },
          {
            path: '/egenie-cloud-wms/afterSales/returnRegistration/index',
            exact: true,
            title: '退件登记',
            component: React.lazy(() => import('./pages/afterSales/returnRegistration/index')),
            permissionId: '60031',
          },
          {
            path: '/egenie-cloud-wms/afterSales/compensationManage/index',
            title: '赔付管理',
            exact: true,
            component: React.lazy(() => import('./pages/afterSales/compensationManage/index')),
            permissionId: '60162',
          },
        ],
      },
      {
        path: '/egenie-cloud-wms/packAndDispatch/index',
        exact: true,
        title: '打包发货',
        component: React.lazy(() => import('./pages/packAndDispatch/index')),
        permissionId: '1651',
      },
      {
        path: '/egenie-cloud-wms/stockIn/index',
        exact: true,
        title: '入库单',
        component: React.lazy(() => import('./pages/stockIn/index')),
        permissionId: '60006',
      },
      {
        path: '/egenie-cloud-wms/stockOut/index',
        exact: true,
        title: '出库单',
        component: React.lazy(() => import('./pages/stockOut/index')),
        permissionId: '60013',
      },
      {
        path: '/egenie-cloud-wms/supplierManage/index',
        exact: true,
        title: '供应商管理',
        component: React.lazy(() => import('./pages/supplierManage/index')),
        permissionId: '60014',
      },
      {
        path: '/egenie-cloud-wms/shopManage/index',
        exact: true,
        title: '店铺管理',
        component: React.lazy(() => import('./pages/shopManage/index')),
        permissionId: '60015',
      },
      {
        path: '/egenie-cloud-wms/exchangeCollarLabel/index',
        exact: true,
        title: '换标',
        component: React.lazy(() => import('./pages/exchangeCollarLabel/index')),
        permissionId: '60019',
      },
      {
        path: '/egenie-cloud-wms/qualityTesting/index',
        exact: true,
        title: '质检',
        component: React.lazy(() => import('./pages/qualityTesting/index')),
        permissionId: '60096',
      },
      {
        path: '/egenie-cloud-wms/qualityDefectiveGoodsInformation/index',
        exact: true,
        title: '质检次品查询',
        component: React.lazy(() => import('./pages/qualityDefectiveGoodsInformation/index')),
        permissionId: '60098',
      },
      {
        path: '/egenie-cloud-wms/qualityResult/index',
        exact: true,
        title: '质检结果查询',
        component: React.lazy(() => import('./pages/qualityResult/index')),
        permissionId: '60171',
      },
      {
        path: '/egenie-cloud-wms/goodsInformation/index',
        exact: true,
        title: '商品信息',
        component: React.lazy(() => import('./pages/goodsInformation/index')),
        permissionId: '60087',
      },
      {
        path: '/egenie-cloud-wms/inventoryVariance/index',
        exact: true,
        title: '盘点差异',
        component: React.lazy(() => import('./pages/inventoryVariance/index')),
        permissionId: '60100',
      },
      {
        path: '/egenie-cloud-wms/deviceManage/index',
        exact: true,
        title: '设备管理',
        component: React.lazy(() => import('./pages/moduleEfficiency/deviceManage/index')),
        permissionId: '60152',
      },
      {
        path: '/egenie-cloud-wms/operationModuleProgramme/index',
        exact: true,
        title: '作业模块方案',
        component: React.lazy(() => import('./pages/moduleEfficiency/operationModuleProgramme/index')),
        permissionId: '60153',
      },
      {
        path: '/egenie-cloud-wms/tenantManage/index',
        title: '云仓租户管理',
        exact: true,
        component: React.lazy(() => import('./pages/tenantManage/index')),
        permissionId: '60054',
      },
      {
        path: '/egenie-cloud-wms/warehouse/index',
        title: '仓库信息',
        exact: true,
        component: React.lazy(() => import('./pages/warehouse/index')),
        permissionId: '2662',
      },
      {
        path: '/egenie-cloud-wms/warehouseBin/index',
        title: '库位信息2.0',
        exact: true,
        component: React.lazy(() => import('./pages/warehouseBin/index')),
        permissionId: '60154',
      },
      {
        path: '/egenie-cloud-wms/courier/index',
        title: '快递公司2.0',
        exact: true,
        component: React.lazy(() => import('./pages/courier/index')),
        permissionId: '60102',
      },
      {
        path: '/egenie-cloud-wms/materialManage/index',
        title: '物料管理',
        exact: true,
        component: React.lazy(() => import('./pages/materialManage/index')),
        permissionId: '60160',
      },
      {
        path: '/egenie-cloud-wms/plusServiceApprove/index',
        title: '货主增值服务审批',
        exact: true,
        component: React.lazy(() => import('./pages/plusServiceApprove/index')),
        permissionId: '60167',
      },
      {
        path: '/egenie-cloud-wms/moduleEfficiencyReport/index',
        exact: true,
        title: '模块效率大屏',
        component: React.lazy(() => import('./pages/moduleEfficiency/moduleEfficiencyReport/index')),
        permissionId: '60187',
      },
      {
        path: '/egenie-cloud-wms/stockTransfer/index',
        title: '调拨单',
        exact: true,
        component: React.lazy(() => import('./pages/stockTransfer/index')),
        permissionId: '60193',
      },
      {
        path: '/egenie-cloud-wms/parcelListManage/index',
        title: '包裹单管理',
        exact: true,
        component: React.lazy(() => import('./pages/parcelListManage/index')),
        permissionId: '60197',
      },
      {
        path: '/egenie-cloud-wms/containerManage/index',
        title: '容器管理',
        exact: true,
        component: React.lazy(() => import('./pages/containerManage/index')),
        permissionId: '60201',
      },
      {
        path: '/egenie-cloud-wms/ironPrint/index',
        title: '烫印',
        exact: true,
        component: React.lazy(() => import('./pages/ironPrint/index')),
        permissionId: '60204',
      },
      {
        path: '/egenie-cloud-wms/skuRelevance/index',
        title: 'SKU对应关系',
        exact: true,
        component: React.lazy(() => import('./pages/skuRelevance/index')),
        permissionId: '60207',
      },
      {
        path: '/egenie-cloud-wms/materialInventoryManage/index',
        title: '物料出入库',
        exact: true,
        component: React.lazy(() => import('./pages/materialInventoryManage/index')),
        permissionId: '60231',
      },
      {
        path: '/egenie-cloud-wms/replenishmentManage',
        title: '补货管理',
        exact: true,
        component: React.lazy(() => import('./pages/replenishmentManage/index')),
      },
    ],
  },
];
